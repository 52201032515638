<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5" style="margin-top: 10px; margin-bottom: 10px">
        <b-form-group
          :description="description"
          label="借用申請"
          label-size="lg"
        >
          <div>
            <b-form-select
              v-model="selected"
              :options="items"
              @change="check"
            />
          </div>
          <br/>
          <template v-if="selected === '其他'">
            <b-form-input
              v-model="other"
              maxlength="10"
              placeholder="自行填寫要借用的物品"
              required
              @input="check"
            />
            <br/>
          </template>
          <b-form-input
            v-model="text"
            maxlength="10"
            placeholder="借用原因"
            required
            @input="check"
          />
          <div class="text-right">
            <p>{{ text.length }} / 10</p>
          </div>
          <b-button
            :disabled="!isFulfilled || applyLimit >= 5"
            :variant="isFulfilled ? 'primary' : 'secondary'"
            pill
            @click="submit"
            @submit.prevent="submit"
          >
            提出申請
          </b-button>
        </b-form-group>
      </div>
      <div class="col-lg-7 container-fluid" style="margin-top: 10px; margin-bottom: 10px">
        <b-row
          class="justify-content-between"
          style="margin: auto"
        >
          <b-row
            align-v="center"
            no-gutters
            size="sm"
          >
            <h5 style="margin: 0 10px 0 0">
              借用紀錄
            </h5>
            <b-form-checkbox
              v-model="includeHistory"
              switch
            >包含歷史紀錄
            </b-form-checkbox>
          </b-row>
          <b-form-select
            v-model="serial"
            :options="serials"
            class="col-lg-4"
          />

          <b-form-radio-group
            v-model="itemType"
            :options="itemOptions"
            button-variant="outline-info"
            buttons
          />
        </b-row>
        <b-table
          id="records"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-function="itemFilter"
          :items="records"
          :per-page="perPage"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :tbody-tr-class="warn"
          class="text-center"
          empty-filtered-text="沒有符合的紀錄"
          empty-text="沒有借用紀錄"
          fixed
          hover
          outlined
          show-empty
          striped
          @filtered="onFiltered"
        >
          <template #cell(loanDate)="row">
            {{ row.value | formatDate }}
          </template>
          <template #cell(returnDate)="row">
            {{ row.value | formatDate }}
          </template>
          <template #cell(status)="row">
            {{ row.value }}
          </template>
          <template #cell(action)="row">
            <b-button
              v-if="row.item.status === '申請中'"
              variant="danger"
              @click="cancel(row.item.recordId)"
            >取消申請
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="rows"
          align="fill"
          aria-controls="records"
          first-number
          last-number
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BorrowApply",
  data() {
    return {
      description: "請選擇要借用的物品及借用原因",
      applyLimit: 0,
      selected: null,
      items: [
        {text: "請選擇欲借用的物品", value: null},
        {text: "鑰匙", value: "鑰匙"},
        {text: "早離卡", value: "早離卡"},
        {text: "臨時卡", value: "臨時卡"},
        {text: "推車", value: "推車"},
        {text: "其他", value: "其他"}
      ],

      other: "",
      text: "",
      isFulfilled: false,

      itemType: "全部",
      itemOptions: [
        {text: "全部", value: "全部"},
        {text: "鑰匙", value: "鑰匙"},
        {text: "早離卡", value: "早離卡"},
        {text: "臨時卡", value: "臨時卡"},
        {text: "推車", value: "推車"},
        {text: "其他", value: "其他"}
      ],
      count: {
        "鑰匙": 0,
        "早離卡": 0,
        "臨時卡": 0,
        "推車": 0,
        "其他": 0
      },
      includeHistory: false,
      serial: "全部",
      serials: [],
      statusIncludes: [],

      records: [],
      fields: [
        {key: "itemType", label: "類型", tdClass: "align-middle"},
        {key: "itemName", label: "編號/名稱", tdClass: "align-middle"},
        {key: "loanDate", label: "借用時間", sortable: true, tdClass: "align-middle"},
        {key: "returnDate", label: "歸還時間", sortable: true, tdClass: "align-middle"},
        {key: "status", label: "狀態", sortable: true, tdClass: "align-middle"},
        {key: "action", label: "", tdClass: "align-middle"}
      ],
      sortBy: "loanDate",
      sortDesc: true,

      rows: 0,
      currentPage: 1,
      perPage: 8
    }
  },
  mounted() {
    if (!this.checkPermission()) {
      this.$bvModal.msgBoxOk(
        "非住宿生不開放借用", {
          title: "提醒",
          okTitle: "確定",
          okVariant: "primary",
          centered: true
        }).then(value => {
        if (value) {
          this.$router.push("/");
        }
      });
    }
    axios.get("/api/item/")
    .then((response) => {
      for (let key in response.data) {
        const record = response.data[key];
        if (record.status === "申請中")
          this.applyLimit++;
        else if (record.status === "借用中")
          this.count[record["item_type"]]++;

        this.records.push({
          recordId: record["record_id"],
          serial: record["serial"],
          itemType: record["item_type"],
          itemName: record["item_name"],
          applyDate: record["apply_date"],
          loanDate: record["loan_date"],
          returnDate: record["return_date"],
          credential: record["credential"],
          reason: record["reason"],
          status: record["status"]
        });
      }
      this.getSerials();

      this.check();
      this.rows = this.records.length;
    }).catch((error) => window.console.log(error));
  },
  methods: {
    checkPermission() {
      const bedHistories = this.$store.state.userState.activeBedHistories;
      return bedHistories.length > 0;
    },
    check() {
      const limit = [
        "鑰匙", "早離卡", "臨時卡"
      ];
      if (this.applyLimit >= 5) {
        this.description = "申請次數已達上限，請洽櫃台";
        return;
      } else if (limit.includes(this.selected)) {
        if ((this.selected === "鑰匙" && this.count["鑰匙"] >= 3) || (((this.selected === "早離卡" || this.selected === "臨時卡") && (this.count["早離卡"] + this.count["臨時卡"]) >= 1))) {
          this.description = this.selected + "借用已達上限，請洽櫃台";
          return;
        }
      }
      this.isFulfilled = ((this.selected !== "其他" && this.selected !== null) || (this.selected === "其他" && this.other)) && this.text;
      this.description = this.isFulfilled ? "申請後請至櫃台告知值班人員" : "請選擇要借用的物品及借用原因";
    },
    getSerials() {
      this.serials.push({
        text: "全部",
        value: "全部"
      });
      axios.get("/api/user/serials")
      .then((serials) => {
        serials = serials.data.reverse();
        for (let serial of serials) {
          serial = serial.substr(0, 4);
          this.serials.push({
            text: serial,
            value: serial
          });
        }
      });
    },
    cancel(recordId) {
      this.$bvModal.msgBoxConfirm("確定要刪除嗎？", {
        title: '確認',
        okVariant: 'danger',
        okTitle: '確定',
        cancelTitle: '取消',
        centered: true
      })
      .then(value => {
        if (value) {
          axios.delete("/api/item/delete/" + recordId)
          .then(() => {
            window.location.reload();
            this.mounted();
          }).catch((error) => {
            if (error.response.status === 500)
              window.alert(error.response.data.msg);
            else
              window.alert(error);
          });
        }
      });
    },
    submit() {
      if (!this.isFulfilled) return;

      this.$bvModal.msgBoxConfirm(`確定要申請借用${this.selected === '其他' ? this.other : this.selected}嗎？`, {
        title: '確認',
        okVariant: 'primary',
        okTitle: '確定',
        cancelTitle: '取消',
        centered: true
      })
      .then((value) => {
        if (value) {
          let formData = new FormData();
          formData.append("item_type", this.selected);
          formData.append("item_name", this.other);
          formData.append("reason", this.text);
          axios.post("/api/item/", formData)
          .then(() => {
            window.location.reload();
            this.mounted();
          })
          .catch((error) => {
            if (error.response.status === 500) {
              window.alert(error.response.data.msg);
              this.upload.sendBtnMsg = "重新申請";
            } else {
              window.alert(error.response.data.msg);
            }
          });
        }
      });
    },
    warn(item, type) {
      if (type !== "row") return;

      const diff = new Date() - new Date(item.loanDate);
      if (item.status === "借用中") {
        if (item.itemType === "鑰匙" && diff / (1000 * 60) >= 10)
          return "table-danger";
        else if (item.itemType === "早離卡" && diff / (1000 * 60 * 60 * 24) >= 1)
          return "table-danger";
        else if (item.itemType === "臨時卡" && diff / (1000 * 60 * 60 * 24) >= 30)
          return "table-danger";
        else if (item.itemType === "推車" && diff / (1000 * 60 * 60) >= 1)
          return "table-danger";
      }
    },
    itemFilter(item, filter) {
      const list = filter[0] ? ["申請中", "借用中", "申請逾期", "已歸還"] : ["申請中", "借用中"];
      return (list.includes(item.status)) && (filter[1] === "全部" || item.serial.includes(filter[1])) && (filter[2] === "全部" || item.itemType === filter[2]);
    },
    onFiltered(items) {
      this.rows = items.length;
      this.currentPage = 1;
    }
  },
  computed: {
    filter() {
      return [this.includeHistory, this.serial, this.itemType];
    }
  },
  filters: {
    formatDate(str) {
      if (!str) return "X";
      const date = new Date(str).toISOString();
      return date.slice(0, 10) + " " + date.slice(11, 19);
    }
  }
}
</script>

<style scoped>

</style>
